import { FunctionComponent, /* useContext, */ useEffect, useMemo } from 'react'
import { CLEVERTAP_ID, PLATFORM_ORIGIN } from 'config'
import { GetStaticProps } from 'next'
import { HomePageRario } from 'components/HomePage'
import { trackEvent } from '@rario/shared-components'
import { useRouter } from 'next/router'
import { getCMSDataForPage } from 'api/CMS.api'
import { MobileHomePage } from 'interfaces/homePage'
import { facetValuesProps } from 'constant/buyPage'
import { useCustomUser } from '../src/contexts/CustomUserProvider'
import InfoScreen from 'components/InfoScreen'
import { isAndroid } from 'utils/utils'
import HomeLanding from 'components/HomeLanding'
import { API_HEADER_COOKIES, APP_PLATFORM } from 'constant'
import { useCookies } from 'react-cookie'
import UserWalletProvider from 'contexts/UserWalletProvider'
import { ThemeEnum } from 'interfaces'

const IndexPage: FunctionComponent<{
  packsLeagueFacetValues: facetValuesProps
  homePageData: MobileHomePage
}> = ({ homePageData }) => {
  const { query } = useRouter()
  const { user } = useCustomUser()
  // const appConfig = useContext(AppConfigContext)
  // const { isPracticeMatchRequired } = appConfig
  const [cookies] = useCookies(['is_onboarding_video_watched', API_HEADER_COOKIES.APP_PLATFORM])
  // const [showWelcomeScreen, setShowWelcomeScreen] = useState<boolean>(
  //   cookies.is_onboarding_video_watched !== 'true' && !!getWelcomeState(user)
  // )
  const isAndroidDevice = isAndroid()

  function notifyMe() {
    if (!('Notification' in window)) {
      // browser doesnt support notifications
    } else if (Notification.permission !== 'denied') {
      // We need to ask the user for permission
      Notification.requestPermission()
    }
  }

  const isSupported = () =>
    typeof window !== 'undefined' &&
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window

  const isWebviewApp = useMemo(() => {
    return (
      Object.keys(cookies).includes(API_HEADER_COOKIES.APP_PLATFORM) &&
      cookies[API_HEADER_COOKIES.APP_PLATFORM] === APP_PLATFORM.WEBVIEW_APP
    )
  }, [cookies[API_HEADER_COOKIES.APP_PLATFORM]])

  const initializeClevertap = async () => {
    const clevertap = await import('clevertap-web-sdk')
    clevertap.default.init(CLEVERTAP_ID || '')
    clevertap.default?.notifications.push({
      titleText: 'Would you like to receive Push Notifications?',
      bodyText:
        'We promise to only send you relevant content and give you updates on your transactions regarding purchase and sell',
      okButtonText: 'Ok',
      rejectButtonText: 'No thanks',
      okButtonColor: '#f28046',
    })
  }

  useEffect(() => {
    // only trigger clevertap and consent popup when app is open in desktop view

    if (query.app_platform !== APP_PLATFORM.WEBVIEW_APP && !isWebviewApp && isSupported()) {
      initializeClevertap()
      notifyMe()
    }
  }, [])

  useEffect(() => {
    if (query?.track === 'user' && user) {
      trackEvent('email_verified', 'track')
      trackEvent(`signup_success`, 'track')
    }
  }, [user])

  const isUserLoggedIn = !!user
  const showAppDownloadStrip =
    !(query.app_platform === APP_PLATFORM.WEBVIEW_APP || isWebviewApp) &&
    isAndroidDevice &&
    PLATFORM_ORIGIN === ThemeEnum.RARIO
  if ((query.app_platform === APP_PLATFORM.WEBVIEW_APP || isWebviewApp) && !isUserLoggedIn) {
    return <InfoScreen />
  } else if (!isUserLoggedIn) {
    return <HomeLanding />
  }
  //  else if (showWelcomeScreen && isPracticeMatchRequired) {
  //   return <WelcomeScreen goToHomePage={() => setShowWelcomeScreen(false)} />
  // }
  else {
    return (
      <UserWalletProvider>
        <HomePageRario homePageData={homePageData} showAppDownloadStrip={showAppDownloadStrip} />
      </UserWalletProvider>
    )
  }
}

export const getStaticProps: GetStaticProps = async () => {
  try {
    const { data } = await getCMSDataForPage('mobileHomePageID')
    return {
      props: {
        homePageData: data as MobileHomePage,
      },
      revalidate: 900,
    }
  } catch (_e) {
    return { notFound: true }
  }
}

export default IndexPage
